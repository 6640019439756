import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  loanAmount: number = 100000;
  tenure: number = 12; // In months
  interest: number = 10;
  emi: number = 0;

  isClassActive = false;  // Initially, the class is not active
  // Method to toggle the class
  toggleClass() {
    this.isClassActive = !this.isClassActive;
  }

  cal() {
    let monthlyRate = this.interest / 12 / 100;
    let emi = this.loanAmount * monthlyRate * Math.pow(1 + monthlyRate, this.tenure) /
      (Math.pow(1 + monthlyRate, this.tenure) - 1);
    this.emi = Math.round(emi);
  }

  userForm = {
    name: '',
    email: '',
    number: '',
    regard: ''
  }

  submitForm(e: any) {
    console.log(this.userForm);

  }
}
