<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container align-items-center position-relative">
    <div class="d-flex align-items-center">
      <a class="navbar-brand" href="#">
        <img
          src="assets/images/theme-images/loanon-logo.png"
          width="200"
          alt="logo"
          class="img-fluid"
        />
      </a>
      <a href="" *ngIf="rewardPointSec" class="btn btn-sm btn-outline-warning" >{{ rewardPoint || 0}}
        <img src="assets/images/img/reward-icon.png" class="img-fluid" alt="" />
      </a>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="pe-2 ps-2 navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/service">Services</a>
        </li>
					<li><a class="nav-link" routerLink="/reward">Reward on Loan</a></li>
					<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
        <li class="nav-item">
          <a class="nav-link" href="contact">Contact Us</a>
        </li>
      </ul>
    </div>

    <div class="d-flex align-items-center position-absolute nav-main-menu">
      <div class="rx_header_btn text-center text-uppercase float-right">
        <button type="button" [routerLink]="['/admin']" class="btn btn-link">
          Admin
        </button>
      </div>

      <div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="ms-1">
        <button class="user" mat-button [matMenuTriggerFor]="menu">
          <img src="assets/images/img/user-icon.png" alt="" />
        </button>
        <mat-menu #menu="matMenu">
          <h3 class="pe-3 m-0 ps-3">{{ user?.name }}</h3>
          <h3 class="pe-3 m-0 ps-3">{{ user?.contact }}</h3>
          <h3 class="pe-3 m-0 ps-3">
            Referral Code:
            <span class="badge bg-success text-wrap"
              ><b>{{ user?.referralCode }}</b></span
            >
          </h3>
          <button mat-menu-item (click)="logOut()">Logout</button>
        </mat-menu>
      </div>
      <!-- <div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div> -->

    </div>
  </div>
</nav>
