import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { AuthService } from 'src/app/core/service/authService';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  adminForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apiHelper: ApiHelper,
    private toastrService: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    private authService : AuthService
  ){
    this.adminForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      password: ['', Validators.required],
    });
  }


  ngOnInit(): void {
 
  }

  onAdminLogin(): void {
    if (this.adminForm.invalid) {
      return;
    }
    // if (this.adminForm.value.phoneNumber === "loanonnadmin" && this.adminForm.value.password === "7403111222Loanonn") {
    //   this.router.navigate(['/admin']);
    //   this.toastrService.success("Welcome");
    //   sessionStorage.setItem('admin', 'sessionString');
    //   return; 
    // }else {
    //   this.toastrService.error("Password Incorrect");
    // }
    this.authenticate(this.adminForm.value, ApiEndPoints.adminLogin);
  }
  private authenticate(data: any, apiEndPoint: ApiEndPoints) {
    try {
        this.apiHelper.post(data, apiEndPoint).subscribe(
        (response) => {
          var session = response.data.session;
          var profile = response.data.profile;
          let sessionString = JSON.stringify(session);
          sessionStorage.setItem('session', sessionString);
          sessionStorage.setItem('admin', sessionString);
          sessionStorage.setItem('profile', JSON.stringify(profile))
          this.cookieService.set('session', sessionString);
          this.sessionRouter(session,profile)
          this.toastrService.success("Welcome");
        },

        (error) => {
          this.toastrService.error(error.error.message || "Unknown error");
        }
      );
    } catch (error) {
      this.toastrService.error("Error connecting");
    }
  }
  sessionRouter(session: any, profile: any): void {
    // this.authService.running = true;
    if (profile.roles ===  'admin' ) {
      this.authService.isAdmin = true;
      this.router.navigate(['/admin']);
    } else {
      this.toastrService.error("Access Denied");
    }
  }
  
}