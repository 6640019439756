import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { AboutComponent } from './modules/about/about.component';
import { AdminComponent } from './modules/admin/admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import { FullComponent } from './shared/layout/full/full.component';
import { LoanAppComponent } from './modules/loan-app/loan-app.component';
import { HomeComponent } from './modules/home/home.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ServiceComponent } from './modules/service/service.component';
import { LoginComponent } from './modules/login/login.component';
import { SigninComponent } from './modules/signin/signin.component';
import { AdminLoginComponent } from './modules/admin-login/admin-login.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { MyModalComponent } from './modules/my-modal/my-modal.component';
import {MatTabsModule} from '@angular/material/tabs';
import { RewardPageComponent } from './modules/reward-page/reward-page.component';
import { SmartSaveComponent } from './modules/smart-save/smart-save.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AdminComponent,
    HeaderComponent,
    FullComponent,
    LoanAppComponent,
    HomeComponent,
    ContactComponent,
    ServiceComponent,
    LoginComponent,
    SigninComponent,
    AdminLoginComponent,
    MyModalComponent,
    RewardPageComponent,
    SmartSaveComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,MatDialogModule,
    RouterOutlet,
    BrowserAnimationsModule,
    MatTableModule,
    MatMenuModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,MatTabsModule,
    ToastrModule.forRoot() 

  ],
  exports:[
    HeaderComponent,
    FullComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
