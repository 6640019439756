<div class="container">
  <div class="row pt-3">
    <div class="mt-3 col-md-3 card-div col-sm-6 ">
      <div class="card bg-peacock" (click)="openModal('personal')">
        <div class="card-body text-center p-4">
          <img class="img-fluid" src="assets/images/img/personal-loan.png" alt="personal loan" />
          <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLScsFrrGmmkco6eseJpYQiz9JascEUp0LpfWZJPwSd1XJvS7Dg/viewform?usp=sf_link"
            target="_blank">
          </a> -->
          <p class="font-weight-bold pt-3">Personal Loan</p>
        </div>
      </div>
    </div>
    <div class="mt-3 col-md-3 card-div col-sm-6 ">
      <div class="card bg-peacock" (click)="openModal('home')">
        <div class="card-body text-center p-4">
          <img class="img-fluid" src="assets/images/img/home-loan.png" alt="home loan" />
          <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLScsFrrGmmkco6eseJpYQiz9JascEUp0LpfWZJPwSd1XJvS7Dg/viewform?usp=sf_link"
            target="_blank">
          </a> -->
          <p class="font-weight-bold pt-3">Home loan</p>
        </div>
      </div>
    </div>
    <div class="mt-3 col-md-3 card-div col-sm-6 ">
      <div class="card bg-peacock" (click)="openModal('car')">
        <div class="card-body text-center p-4">
          <img class="img-fluid" src="assets/images/img/car-loan.png" alt="car loan" />
          <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLScsFrrGmmkco6eseJpYQiz9JascEUp0LpfWZJPwSd1XJvS7Dg/viewform?usp=sf_link"
            target="_blank">
          </a> -->
          <p class="font-weight-bold pt-3">Car Loan</p>
        </div>
      </div>
    </div>
   
    <div class="mt-3 col-md-3 card-div col-sm-6 ">
      <div class="card bg-peacock " (click)="openModal('credit')">
        <div class="card-body text-center p-4">
          <img class="img-fluid" src="assets/images/img/creditcard.png" alt="credit card" />
          <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLScsFrrGmmkco6eseJpYQiz9JascEUp0LpfWZJPwSd1XJvS7Dg/viewform?usp=sf_link"
            target="_blank">
          </a> -->
          <p class="font-weight-bold pt-3">Other Products</p>
        </div>
      </div>
    </div>

    <!-- <div class="mt-3 col-md-3 card-div col-sm-6 ">
      <div class="card bg-peacock " (click)="openModal('refer')">
        <div class="card-body text-center p-4">
          <img class="img-fluid" src="assets/images/img/refer.png" alt="refer" />
          
          <p class="font-weight-bold pt-3">Referal</p>
        </div>
      </div>
    </div> -->

  </div>
</div>
