import { Component } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  isClassActive = false;  // Initially, the class is not active

  // Method to toggle the class
  toggleClass() {
    this.isClassActive = !this.isClassActive;
  }
}
