import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  numberAdd: FormGroup;
  loginForm: FormGroup;
  otpNumber: FormGroup;
  confirmpasswordForm: FormGroup;
  signinSec: boolean = true;
  otpForm: boolean = false;
  phoneNumberAdd: boolean = false;
  confirmPassword: boolean = false;
  phoneNumber!:string;
  otpCode ! : string
  maskedPhoneNumber! : string;
  constructor(
    private formBuilder: FormBuilder,
    private apiHelper: ApiHelper,
    private toastrService: ToastrService,
    private router: Router,
    private cookieService: CookieService
  ) {
    // LOGIN FORM
    this.loginForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      password: ['', Validators.required],
    });

    // NUMBER ADD
    this.numberAdd = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
    });
    // NUMBER ADD
    // OTP NUMBER ADD
    this.otpNumber = this.formBuilder.group({
      num1: ['', [Validators.required]],
      num2: ['', [Validators.required]],
      num3: ['', [Validators.required]],
      num4: ['', [Validators.required]],
      num5: ['', [Validators.required]],
      num6: ['', [Validators.required]],
    })
    // OTP NUMBER ADD

    // CONFIRM PASSSWORD 
    this.confirmpasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    })
    // CONFIRM PASSSWORD 
    

  }

  // MOBILE FORM


  ngOnInit(): void {
    let session = JSON.parse(sessionStorage.getItem('session')!)
    let profile = JSON.parse(sessionStorage.getItem('profile')!)
    if (profile && session) {
      this.sessionRouter(session, profile)
    }
  }
  
  onSignin(): void {
    if (this.loginForm.invalid) {
      return;
    }

    // if (this.loginForm.value.phoneNumber === "user" && this.loginForm.value.password === "1234") {
    //   this.router.navigate(['/loan-app']);
    //   return;  
    // }

    this.authenticate(this.loginForm.value, ApiEndPoints.signIn);
  }


  private authenticate(data: any, apiEndPoint: ApiEndPoints) {
    try {
      this.apiHelper.post(data, apiEndPoint).subscribe(
        (response) => {
          var session = response.data.session;
          var profile = response.data.profile;
          let sessionString = JSON.stringify(session);
          sessionStorage.setItem('session', sessionString);
          sessionStorage.setItem('profile', JSON.stringify(profile))
          this.cookieService.set('session', sessionString);
          this.sessionRouter(session, profile)
          this.toastrService.success("Welcome");
        },

        (error) => {
          this.toastrService.error(error.error.message || "Unknown error");
        }
      );
    } catch (error) {
      this.toastrService.error("Error connecting");
    }
  }
  sessionRouter(session: any, profile: any): void {
    this.router.navigate(['loan-app']);
  }

  addPhonenum() {
    this.signinSec = false;
    this.phoneNumberAdd = true;
  }
  
  addPhonenum2() {
    this.signinSec = true;
    this.phoneNumberAdd = false;
  }

  phonenumSubmit() {
    if (this.numberAdd.invalid) {
      return;
    }
    const phoneNumberData = {
      phoneNumber: this.numberAdd.value.phoneNumber
    };

    this.phoneNumber = this.numberAdd.value.phoneNumber;

    this.apiHelper.post(phoneNumberData, ApiEndPoints.otpAuth).subscribe(
      (res) => {
        this.toastrService.success(res.data);
        this.otpForm = true;
        this.signinSec = false;
        this.phoneNumberAdd = false;
        this.maskedPhoneNumber = this.maskPhoneNumber(this.phoneNumber);
      },
      (error) => {
        this.toastrService.error(error.error?.message || "Unknown error");
      }
    );
  }


  validateOtp() {
    if (this.otpNumber.invalid) {
      return;
    }
    
    this.otpCode = Object.values(this.otpNumber.value).join('');
    const payload = {
      phoneNumber: this.phoneNumber,  // Assuming phone number is saved in a variable
      otp: this.otpCode
    };

    
    this.apiHelper.post({ phoneNumber: this.phoneNumber,otp: this.otpCode }, ApiEndPoints.otpVerify).subscribe(
      (res) => {
        this.toastrService.success(res.data);
        this.otpForm = false;
        this.confirmPassword = true;
      },
      (error) => {
        this.toastrService.error(error.error?.message || "Unknown error");
      }
    );
  }

  moveToNext(event: any, controlName: string) {
    const nextControlMap: { [key: string]: string | null } = {
      num1: 'num2',
      num2: 'num3',
      num3: 'num4',
      num4: 'num5',
      num5: 'num6',
      num6: null 
    };

  
    const nextControl = nextControlMap[controlName];
    if (nextControl && event.target.value.length === 1) {
      const nextField = document.querySelector(`[formControlName="${nextControl}"]`) as HTMLElement;
      nextField?.focus();
    }
  }


  confirmPassfunc() {
    if (this.confirmpasswordForm.invalid) {
      return
    }
    this.confirmpasswordForm.value.phoneNumber = this.phoneNumber
    this.confirmpasswordForm.value.otp = this.otpCode
    
    this.apiHelper.post(this.confirmpasswordForm.value, ApiEndPoints.updatePassword).subscribe(
      (res) => {
      this.toastrService.success(res.data);
      this.router.navigate(['/login']);
    },
      (error) => {
        this.toastrService.error(error.error.message || "Unknown error");
      })
  }

maskPhoneNumber(phoneNumber: string): string {
    const lastFourDigits = phoneNumber?.slice(-4);
    const maskedSection = phoneNumber?.slice(0, -4).replace(/\d/g, '*');
    return maskedSection + lastFourDigits;
  }

}
