<div class="p-4">
    <!-- <form #form="ngForm">
        <div class="form-group">
            <label>Name</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="userName" placeholder="name">
        </div>
        <div class="form-group">
            <label>Email address</label>
            <input type="email" class="form-control" [(ngModel)]="userEmail" #emailInput="ngModel" required email
                placeholder="name@example.com" name="email">
            <div *ngIf="emailInput.invalid &&  (emailInput.dirty || emailInput.touched)" class="help-block"
                style="color: #813838">Email is Not Vliad!</div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Contact Number</label>
                    <input type="number" class="form-control" [(ngModel)]="userNumber" #numberInput="ngModel" required
                        number placeholder="number" name="number">
                    <div *ngIf="numberInput.invalid &&  (numberInput.dirty || numberInput.touched)" class="help-block"
                        style="color: #813838">Add number</div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group ">

                    <div class="d-flex align-items-center  ">
                        <input type="checkbox" name="referal" id="referal" [(ngModel)]="myCheckbox">
                        <label for="referal" class="pl-2 text-nowrap ">Referal</label>
                    </div>
                    <div class="d-flex align-items-center justify-content-start">
                        <div class="pl-2">
                            <input [disabled]="!myCheckbox" maxlength="6" minlength="6" [(ngModel)]="referralCode" name="referalCode" type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Type of Loan </label>
                    <input type="text" disabled *ngIf="this.data === 'home'" [value]="'Home Loan'" class="form-control" [(ngModel)]="selectedValue" placeholder="Home Loan" name="home">
                    <input type="text" disabled *ngIf="this.data === 'car'" [value]="'Car Loan'" class="form-control" [(ngModel)]="selectedValue" placeholder="Car Loan" name="car">
                    <input type="text" disabled *ngIf="this.data === 'credit'" [value]="'Credit Card'" class="form-control" [(ngModel)]="selectedValue" placeholder="Credit Card" name="credit">
                    <input type="text" disabled *ngIf="this.data === 'personal'" [value]="'Personal Loan'" class="form-control"[(ngModel)]="selectedValue" placeholder="Personal Loan"  name="personal"> 
                 
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Upload - ID Proof</label>
                    <input (change)="onFileSelected($event)" type="file" class="pt-2 form-control-file">
                </div>
            </div>
        </div>
        <button type="submit" [disabled]="form.invalid" (click)="onSubmit()" class="btn btn-primary">Submit</button>
    </form> -->

    <form [formGroup]="loanForm" (ngSubmit)="onSubmitform()">
        <div class="form-group">
            <label><small class="text-danger">*</small> Name</label>
            <input type="text" class="form-control" name="name" formControlName="name" placeholder="name">
        </div>
        <div class="form-group">
            <label>  Email address</label>
            <input type="email" class="form-control" formControlName="email" placeholder="name@example.com"
                name="email">
            <!-- <div *ngIf="emailInput.invalid &&  (emailInput.dirty || emailInput.touched)" class="help-block"
                style="color: #813838">Email is Not Vliad!</div> -->
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label><small class="text-danger">*</small> Contact Number</label>
                    <input type="number" class="form-control" formControlName="contact" required number
                        placeholder="number" name="number">
                    <!-- <div *ngIf="numberInput.invalid &&  (numberInput.dirty || numberInput.touched)" class="help-block"
                        style="color: #813838">Add number</div> -->
                </div>
            </div>

            <!-- <div class="col-md-6 col-sm-12">
                <div class="form-group ">

                    <div class="d-flex align-items-center  ">
                        <input type="checkbox" name="referal" formControlName="enableInput">
                        <label for="referal" class="pl-2 referal text-nowrap ">Referal Mobile Number</label>
                    </div>
                    <div class="d-flex align-items-center justify-content-start">
                        <div class="pl-2">
                            <input [disabled]="!loanForm.get('enableInput')?.value" maxlength="10" minlength="10" formControlName="refer"
                                name="referalCode" type="text" class="form-control" required>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label><small class="text-danger">*</small> Type of Loan </label>
                    <input type="text" class="form-control" *ngIf ="data !== 'credit'" formControlName="loanType" placeholder="{{ getPlaceholder() }}" readonly>

                    <select class="form-select" formControlName="loanType" required *ngIf="data === 'credit'">
                        <option selected>OtherProducts</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="Over Draft">Over Draft</option>
                        <option value="Education Loan">Education Loan</option>
                        <option value="Gold Loan">Gold Loan</option>
                      </select>

                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Upload - ID Proof </label>
                    <input (change)="onFileSelected($event)" type="file" class="pt-2 form-control-file">
                </div>
            </div>
        </div>
        <button type="submit" [disabled]="!loanForm.valid" class="btn btn-primary">Submit</button>
    </form>

</div>