import { Component } from '@angular/core';

@Component({
  selector: 'app-smart-save',
  templateUrl: './smart-save.component.html',
  styleUrls: ['./smart-save.component.scss']
})
export class SmartSaveComponent {
  isClassActive = false;  // Initially, the class is not active

  // Method to toggle the class
  toggleClass() {
    this.isClassActive = !this.isClassActive;
  }
}
