import { Component, Inject, OnInit, SimpleChange } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { PeriodicElement } from '../admin/admin.component';

@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.scss'],
})
export class MyModalComponent implements OnInit {
  myCheckbox: boolean = false;
  loanForm: FormGroup;
  items: PeriodicElement[] = [];
  dataSource: PeriodicElement[] = [];
  rewardPoint: any;
  user: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<MyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private Apihelper: ApiHelper,
    public toastrService: ToastrService) {
    this.loanForm = this.fb.group({
      enableInput: [false],
      name: new FormControl('' ,Validators.required),
      email: new FormControl(''),
      contact: new FormControl('', Validators.required),
      loanType: new FormControl(''),
      refer: [{ value: ''}],
      file: [null]
    })
    
    this.loanForm.get('enableInput')?.valueChanges.subscribe(enableInput => {
      const referControl = this.loanForm.get('refer');
      if (enableInput) {
        referControl?.enable();
      } else {
        referControl?.disable();
      }
    });
  }
  ngOnInit(): void {
    this.setLoanType();
    const userDetails = sessionStorage.getItem('profile');
    if (userDetails) {
      this.user = JSON.parse(userDetails);
    }
    console.log(this.user.contact);
  }

  // userGet() {
  //   this.Apihelper.post({}, ApiEndPoints.userget)?.subscribe(
  //     response => {
  //       this.items = response.data;
  //       this.dataSource = [...this.items]; // initialize dataSource
  //       const rewardCode = this.dataSource?.map((e) => {
  //         if (this.user?.referralCode === e?.referralCode) {
  //           this.rewardPoint = e?.rewards;
  //         }
  //       })
        
  //     },
  //     error => {
  //       this.toastrService.error('Error fetching user data.');
  //     }
  //   );
  // }

  ngOnChanges(changes: SimpleChange) {
    if (changes['currentValue']) {
      this.setLoanType();
    }
  }
  getPlaceholder(): string {
    switch (this.data) {
      case 'home':
        return 'Home Loan';
      case 'car':
        return 'Car Loan';
      case 'credit':
        return 'Credit Card';
      case 'personal':
        return 'Personal Loan';
      default:
        return '';
    }
  }

  setLoanType() {
    let value = '';
    switch (this.data) {
      case 'home':
        value = 'Home Loan';
        break;
      case 'car':
        value = 'Car Loan';
        break;
      case 'credit':
        value = 'Credit Card';
        break;
      case 'personal':
        value = 'Personal Loan';
        break;
    }
    this.loanForm.get('loanType')?.setValue(value);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.loanForm.get('file')?.setValue(file);

  }

  onSubmitform() {
    const formData: FormData = new FormData();
    // Append the fields from the form group
    formData.append('name', this.loanForm.get('name')?.value);
    formData.append('email', this.loanForm.get('email')?.value);
    formData.append('contact', this.loanForm.get('contact')?.value);
    formData.append('refer', this.user.contact);
    formData.append('loanType', this.loanForm.get('loanType')?.value);
    formData.append('file', this.loanForm.get('file')?.value);
    formData.append("reportProgress", 'true');
    // formData.append(this.user.contact);
  

    this.Apihelper.post(formData, ApiEndPoints.requestLoan).subscribe(
      (response) => {

        if (response && response.data) {
          this.toastrService.success(
            'Form Submitted..! We will contact you soon'
          );
        } else {
          this.toastrService.error('Error submitting the form.');
        }
        setTimeout(() => {
          this.dialogRef.close();
        }, 1000);
      },
      (error) => {
        console.error('Error occurred:', error);
        this.toastrService.error(
          'An error occurred while submitting the form.'
        );
        setTimeout(() => {
          this.dialogRef.close();
        }, 1000);
      }
    );
  }
  // }

}
