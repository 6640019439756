import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MyModalComponent } from '../my-modal/my-modal.component';

@Component({
  selector: 'app-loan-app',
  templateUrl: './loan-app.component.html',
  styleUrls: ['./loan-app.component.scss']
})
export class LoanAppComponent implements OnInit{
  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {
    
  }
  openModal(val:any){
    this.dialog.open(MyModalComponent, {
      width: '500px',
      data : val,
      // data: {val, message: 'Hello from the dialog!' }
    });
  
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
}
