import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
export interface PeriodicElement {
  password: any;
  id: number;
  name: string;
  no: number;
  contact: number;
  phoneNumber: number;
  rewards: string;
  referralCode: string;
  email: string;
  rewardsToUpdate?: string;
  status: any;
  profileId?: any;
}
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  allData: any = [];
  btnVerify: boolean = true;
  allUserdata: PeriodicElement[] = [];
  allUsers: PeriodicElement[] = [];
  allPendingdata: PeriodicElement[] = [];
  displayedColumns: string[] = ['position', 'name', 'number', 'symbol'];
  dataSource: PeriodicElement[] = [];
  constructor(
    private Apihelper: ApiHelper,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.loanRequests();
    this.userGet();
   
  }

  verify(id: number, status: string) {
    this.Apihelper.post({ userId: id, status: status }, ApiEndPoints.userAuth).subscribe(
      (response) => {
        this.toastrService.success('User Accepted');
      },
      (error) => {
        console.log(error)
        this.toastrService.error(error.message);
      })
  }
  reject(id: number, status: string) {
    // this.btnVerify = !this.btnVerify;
    this.Apihelper.post({ userId: id, status: status }, ApiEndPoints.userAuth).subscribe(
      (response) => {
        this.toastrService.error('User Rejected');
      },
      (error) => {
        console.log(error)
        this.toastrService.error(error.message);
      })
  }

  loanRequests() {
    this.Apihelper.post({}, ApiEndPoints.requestedLoans).subscribe(
      response => {
        this.allData = response.data;
        console.log(this.allData);
      },
      error => {
        this.toastrService.error('Error fetching loan requests.');
      }
    );
  }

  userGet() {
    this.Apihelper.post({}, ApiEndPoints.alluserData).subscribe(
      response => {
        console.log(response);
        
        this.allUsers = response.data;
        
        this.allUserdata = this.allUsers.filter(user => user.status === 'accepted');
        this.allPendingdata = this.allUsers.filter(user => user.status === 'pending');
        this.dataSource = [...this.allUsers];
      },
      error => {
        this.toastrService.error('Error fetching user data.');
      }
    );
  }

  updateRewards(item: PeriodicElement, index: number) {
    if (item.rewardsToUpdate && item.rewardsToUpdate.trim() !== '') {
      const currentRewards = isNaN(parseInt(item.rewards, 10)) ? 0 : parseInt(item.rewards, 10);
      const newRewards = parseInt(item.rewardsToUpdate.trim(), 10);
      console.log(newRewards,'newRewards');
      

      if (!isNaN(newRewards)) {
        const updatedRewards = (currentRewards + newRewards).toString();
        console.log(updatedRewards,'updatedRewards');
        
        const requestBody = {
          userId: item.profileId,
          updatedRewards: updatedRewards
        };
        console.log(requestBody,'requestBody');
        
        this.Apihelper.post(requestBody, ApiEndPoints.profileRewardsEdit).subscribe(
          response => {
            console.log(response);
            
            if (response.apiStatus == 200) {
              this.dataSource[index].rewards = updatedRewards;

              this.toastrService.success(`Rewards updated for ${item.name}. New value: ${updatedRewards}`);
              item.rewardsToUpdate = '';
            } else {
              this.toastrService.error(`Failed to update rewards for ${item.name}.`);
            }
          },
          error => {
            this.toastrService.error('Error updating rewards. Please try again later.');
          }
        );
      } else {
        this.toastrService.error('Please enter a valid numeric rewards value.');
      }
    } else {
      this.toastrService.error('Please enter a rewards value.');
    }
  }

  deleteUser(id:any){
    console.log(id);
    
    this.Apihelper.post({ id: id }, ApiEndPoints.removeUser).subscribe(
      (response) => {
        
        this.toastrService.success('Account Deleted Succesfully');
        this.userGet();
      },
      (error) => {
        this.toastrService.error(error.message);
      })
  }
  deleteRequest(id:any){
    this.Apihelper.post({ id: id }, ApiEndPoints.removeLoan).subscribe(
      (response) => {
        this.toastrService.success('Account Deleted Succesfully');
        this.loanRequests();
      },
      (error) => {
        this.toastrService.error(error.message);
      })
  }

}



