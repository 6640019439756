<div class="login-body">
    <div class="container" id="container">
        <div class="form-container sign-in">
            <form [formGroup]="adminForm" (ngSubmit)="onAdminLogin()"  >
                <h2>Admin Login</h2>
                <input type="text" formControlName="phoneNumber" required placeholder="User Name"/>
                <input type="password" formControlName="password" required placeholder="Password"/>
                <!-- <a href="#">→ Forget Your Password? ←</a> -->
                <button>Login In</button>
            </form>
        </div>
        <div class="toggle-container">
            <div class="toggle">
                <div class="toggle-panel toggle-right">
                   
                    <h1>Hello, Friend!</h1>
                    <p>Register with your personal details to use all of site features</p>
                    <button class="hidden" [routerLink]="['/signin']">Sign Up</button>
                    <button class="hidden mt-3" [routerLink]="['/home']">Home Page</button>
                </div>
            </div>
        </div>
    </div>
</div>