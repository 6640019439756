import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {
  signForm: FormGroup;
  sigupSec: boolean = true;
  otpForm: boolean = false;
  // otpNumber: FormGroup;
  checkMail: boolean = false;
  alreadyAccount: boolean = false;
  phoneNumber!: string;
  otpCode!: string;
  userName!: string;
  maskedPhoneNumber: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private apiHelper: ApiHelper,
    private toastrService: ToastrService,
    private router: Router,
    private cookieService: CookieService
  ) {

    this.signForm = this.formBuilder.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    // OTP NUMBER ADD
    // this.otpNumber = this.formBuilder.group({
    //   num1: ['', [Validators.required]],
    //   num2: ['', [Validators.required]],
    //   num3: ['', [Validators.required]],
    //   num4: ['', [Validators.required]],
    //   num5: ['', [Validators.required]],
    //   num6: ['', [Validators.required]],
    // })
    // OTP NUMBER ADD

  }
  ngOnInit(): void {
    let session = JSON.parse(sessionStorage.getItem('session')!)
    let profile = JSON.parse(sessionStorage.getItem('profile')!)
    if (profile && session) {
      this.sessionRouter(session, profile)
    }
  }
  onSignUp(): void {
    if (this.signForm.invalid) {
      return;
    }
    this.phoneNumber = this.signForm.value.phoneNumber;
    this.userName = this.signForm.value.name;
    this.checkMail = false;  
    this.apiHelper.post(this.signForm.value, ApiEndPoints.signUp).subscribe(
      (res) => {
        if (res) {
          this.toastrService.success('Sign-up Request successful');  
          this.checkMail = true;  
          this.sigupSec = false;  
        }
      },
      (error) => {
        if (error.status === 404) {
          this.checkMail = false;  
          this.alreadyAccount = true;
          this.toastrService.error(error.error?.message || "Account already exists.");
        } else {
          
          this.toastrService.error(error.error?.message || "An unknown error occurred.");
        }
      }
    );
    

    // this.apiHelper.post(this.signForm.value, ApiEndPoints.sendOTP).subscribe((res) => {
    //   this.toastrService.success(res.data);
    //   this.otpForm = true;
    //   this.checkMail = false;
    //   this.sigupSec = false;
    //   this.maskedPhoneNumber = this.maskPhoneNumber(this.phoneNumber);

    // });

    this.checkMail = true;
    this.sigupSec = false;

  }

  backSignup() {
    this.sigupSec = true;
    this.checkMail = false;
    this.alreadyAccount = false;
  }
  // 9633296501
  // private authenticate(data: any, apiEndPoint: ApiEndPoints) {
  //   console.log(data);

  //   try {
  //     this.apiHelper.post(data, apiEndPoint).subscribe(
  //       (response) => {
  //         var session = response.data.session;
  //         var profile = response.data.profile;
  //         let sessionString = JSON.stringify(session);
  //         sessionStorage.setItem('session', sessionString);
  //         sessionStorage.setItem('profile', JSON.stringify(profile))
  //         this.cookieService.set('session', sessionString);
  //         this.sessionRouter(session, profile)
  //         this.toastrService.success("Welcome");
  //       },
  //       (error) => {
  //         this.toastrService.error(error.error.message || "Unknown error");
  //         this.sigupSec = true;
  //         this.otpForm = false;
  //       }
  //     );
  //   } catch (error) {
  //     this.toastrService.error("Error connecting");
  //   }
  // }

  sessionRouter(session: any, profile: any): void {
    this.router.navigate(['loan-app']);
  }

  // validateOtp() {
  //   if (this.otpNumber.invalid) {
  //     return;
  //   }
  //   this.otpCode = Object.values(this.otpNumber.value).join('');

  //   const payload = {
  //     phoneNumber: this.phoneNumber,  // Assuming phone number is saved in a variable
  //     otp: this.otpCode,
  //     name: this.userName,
  //   };

  //   this.authenticate({ phoneNumber: this.phoneNumber, name: this.userName,otp: this.otpCode }, ApiEndPoints.verifyOTP)
  // }

  // moveToNext(event: any, controlName: string) {
  //   const nextControlMap: { [key: string]: string | null } = {
  //     num1: 'num2',
  //     num2: 'num3',
  //     num3: 'num4',
  //     num4: 'num5',
  //     num5: 'num6',
  //     num6: null // No next input for the last one
  //   };

  //   // Move to the next field automatically
  //   const nextControl = nextControlMap[controlName];
  //   if (nextControl && event.target.value.length === 1) {
  //     const nextField = document.querySelector(`[formControlName="${nextControl}"]`) as HTMLElement;
  //     nextField?.focus();
  //   }
  // }

  maskPhoneNumber(phoneNumber: string): string {
    const lastFourDigits = phoneNumber?.slice(-4);
    const maskedSection = phoneNumber?.slice(0, -4).replace(/\d/g, '*');
    return maskedSection + lastFourDigits;
  }

}
