<div class="login-body">
    <div class="container active">
        <div class="form-container sign-up d-flex flex-column justify-content-center h-100" >
            <div *ngIf="sigupSec">
                <form [formGroup]="signForm" (ngSubmit)="onSignUp()" >
                    <h1 class="text-secondary">Create Account</h1>
                    <input type="text"  formControlName="name" required placeholder="Name">
                    <input type="text" formControlName="phoneNumber" required maxlength="10" minlength="10" placeholder="Phone Number"/>
                    <input type="email" formControlName="email" required placeholder="Email ID"/>
                    <input type="password" formControlName="password" required placeholder="Password"/>
                    <button type="submit" [disabled]="signForm.invalid">Sign UP</button>
                </form>
            </div>
            <div *ngIf="checkMail" class="p-5 text-center">
                <img src="/assets/images/img/email-verify.png" class="img-fluid" alt="">
                <p>Your request has been sent. After approval, we will contact you via call.</p>
                <button class="btn btn-success mt-3" (click)="backSignup()">Back</button>
            </div>
            <div *ngIf="alreadyAccount" class="p-5 text-center">
                <img src="/assets/images/img/error.jpg" class="img-fluid w-100" alt="">
                <p>Account number already exists. Please choose another.</p>
                <button class="btn btn-success mt-3" (click)="backSignup()">Back</button>
            </div>

<!-- 
            <form *ngIf="otpForm" [formGroup]="otpNumber" (ngSubmit)="validateOtp()">
                <div class="p-2 text-center">
                  <h4 class="text-danger">Please enter the one time password <br> to verify your account</h4>
                  <div> <span>A code has been sent to</span> <small>{{maskedPhoneNumber}}</small> </div>
                  <div class="inputs d-flex flex-row justify-content-center mt-2">
                    <input class="me-2 text-center form-control rounded" type="text" formControlName="num1" (input)="moveToNext($event, 'num1')"  maxlength="1" /> 
                    <input class="me-2 text-center form-control rounded" type="text" formControlName="num2" (input)="moveToNext($event, 'num2')"  maxlength="1" /> 
                    <input class="me-2 text-center form-control rounded" type="text" formControlName="num3" (input)="moveToNext($event, 'num3')"  maxlength="1" />
                    <input class="me-2 text-center form-control rounded" type="text" formControlName="num4" (input)="moveToNext($event, 'num4')"  maxlength="1" /> 
                    <input class="me-2 text-center form-control rounded" type="text" formControlName="num5" (input)="moveToNext($event, 'num5')"  maxlength="1" />
                    <input class="me-2 text-center form-control rounded" type="text" formControlName="num6" (input)="moveToNext($event, 'num6')"  maxlength="1" />
                  </div>
                  <div class="mt-4"> <button type="submit" class="btn btn-danger px-4 validate">Validate</button> </div>
                </div>
              </form> -->
        </div>
       
        <div class="toggle-container">
            <div class="toggle">
                <div class="toggle-panel toggle-left">
                    <h1>Welcome Back!</h1>
                    <p>Enter your personal details to use all of site features</p>
                    <button class="hidden" routerLinkActive="active" [routerLink]="['/login']">Sign In</button>
                    <button class="hidden mt-3" [routerLink]="['/home']">Home Page</button>
                </div>
            </div>
        </div>
    </div>
</div>

