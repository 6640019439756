<div class="container">
  <mat-tab-group dynamicHeight>
    <mat-tab label="All Users">
      <div class="w-100 pt-4">
        <div class="w-100 table-responsive table-height">
          <table class="w-100 table table-hover table-striped ">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Password</th>
                <th>Email</th>
                <!-- <th>Referal</th> -->
                <th>Rewards</th>
                <th>Action</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of allUserdata; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.phoneNumber }}</td>
                <td>{{ item.password }}</td>
                <td>{{ item.email }}</td>
                <!-- <td>{{ item?.referralCode ?? 0}}</td> -->
                <td>{{ item.rewards }}</td>
                <td>
                  <input type="text" class="form-control" value="10"  placeholder="" [(ngModel)]="item.rewardsToUpdate">
                </td>
                <td>
                  <button type="button" class="btn btn-primary" (click)="updateRewards(item, i)">Submit</button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger" (click)="deleteUser(item.profileId)">Delete User</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="no-data" *ngIf="allUserdata.length <= 0">
            <img src="assets/images/img/no-data.avif" class="nodata" alt="">
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Loan Requested">
      <div class="example-large-box mat-elevation-z4">
        <div class="w-100 pt-4">
          <div class="w-100 table-responsive table-height">
            <table class="w-100 table table-hover table-striped ">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Loan ID </th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Loan Type</th>
                  <th>Email</th>
                  <th>Referal Mobile Num:</th>
                  <th>File</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.contact }}</td>
                  <td>{{ item.loanType }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.refer }}</td>
                  <td>
                    <a [href]="item.file" target="_blank" class="btn btn-border" [download]="item.file">Download</a>
                  </td>
                  <td>
                    <button type="button" class="btn btn-danger" (click)="deleteRequest(item.id)">Delete User</button>
                  </td>
                  <!-- <td>
                    <button type="button" class="btn btn-primary" [ngClass]="btnVerify?'btn-success':'btn-danger'"  (click)="veridy(i)"> Verify</button>
                  </td> -->
                </tr>
              </tbody>
            </table>

            <div class="no-data" *ngIf="allData.length <= 0">
              <img src="assets/images/img/no-data.avif" class="nodata" alt="">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Requested Account">
      <div class="example-large-box mat-elevation-z4">
        <div class="w-100 pt-4">
          <div class="w-100 table-responsive table-height">
            <table class="w-100 table table-hover table-striped ">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Loan ID </th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Password</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allPendingdata; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.profileId }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phoneNumber }}</td>
                  <td>{{ item.password || '' }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <div class="d-flex">
                      <button type="button" class="btn btn-success me-2" [ngClass]="btnVerify?'btn-success':'btn-danger'"  (click)="verify(item.profileId,'accepted')"> Accept</button>
                      <button type="button" class="btn btn-danger" [ngClass]="btnVerify?'btn-success':'btn-danger'"  (click)="reject(item.profileId,'rejected')"> Rejected</button>
                    </div>
                  </td>
                
                </tr>
              </tbody>
            </table>

            <div class="no-data" *ngIf="allPendingdata.length <= 0">
              <img src="assets/images/img/no-data.avif" class="nodata" alt="">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  
</div>
