import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './modules/about/about.component';
import { AdminComponent } from './modules/admin/admin.component';
import { FullComponent } from './shared/layout/full/full.component';
import { LoanAppComponent } from './modules/loan-app/loan-app.component';
import { HomeComponent } from './modules/home/home.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ServiceComponent } from './modules/service/service.component';
import { LoginComponent } from './modules/login/login.component';
import { SigninComponent } from './modules/signin/signin.component';
import { AdminLoginComponent } from './modules/admin-login/admin-login.component';
import { RewardPageComponent } from './modules/reward-page/reward-page.component';
import { authGuard } from './core/guards/auth.guard';
import { adminGuard } from './core/guards/admin.guard';
import { SmartSaveComponent } from './modules/smart-save/smart-save.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'loan-app',
    component: FullComponent,
    children: [
      {
        path: '', component: LoanAppComponent,
        canActivate: [authGuard]
      }
    ],
  },
  {
    path: 'admin',
    component: FullComponent,
    children: [
      {
        path: '',
        component: AdminComponent,
        canActivate: [authGuard,adminGuard],
      },
    ],
  },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login', component: LoginComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'admin-login', component: AdminLoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'rewards', component: RewardPageComponent },
  { path: 'smart-save', component: SmartSaveComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
