import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';

export const adminGuard: CanActivateChildFn = (childRoute, state) => {
  const router: Router = inject(Router);
  if (!sessionStorage.getItem('admin')) {
    router.navigate(["/admin-login"]);
    return false;
  }
  return true;
};
