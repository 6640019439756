import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiHelper {
  ngOnInit(): void {

  }
  constructor(private http: HttpClient, private cookieService: CookieService,) { }

  post(data: any, endPoint: string): Observable<any> {
    let sessionString = sessionStorage.getItem("session");
    let headersJson = new HttpHeaders({ 'Accept': 'application/json' });

    if (sessionString && !endPoint.includes('signin') && !endPoint.includes('signup')) {
        let session = JSON.parse(sessionString);
        let sessionId = session.id;      
        headersJson = headersJson.set('session-id', sessionId);
    }
    let url = environment.baseUrl + endPoint;
    return this.http.post<any>(url, data, { headers: headersJson });
  }
}
